import { useContext, useState } from "preact/hooks";
import { getThemePath } from "../utils";
import { LanguageContext, t } from "./language";

interface Props {
  items: {
    title: string;
    slug: string;
  }[];
}

export const Sidebar = (props: Props) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [language, setLang] = useContext(LanguageContext);

  const onToggleMobileMenu = () => {
    setMenuOpened(!menuOpened);
  };

  const navigateTo = (event: any) => {
    event.preventDefault();
    const id = event.target.dataset.href;
    document.querySelector(`#${id}`)?.scrollIntoView();

    if (menuOpened) {
      setMenuOpened(false);
    }
  };

  const changeLanguage = (lang: string) => (event: any) => {
    event.preventDefault();
    setLang(lang);
  };

  return (
    <aside role="menu" className="navbar">
      <div className="row">
        <a
          href="/"
          className="logo col-lg-12 col-md-12 col-sm-8 col-xs-8"
          title="Sezkla"
        >
          <img
            src={getThemePath("/images/logo_mobile.png")}
            alt=""
            className="mobile"
          />
        </a>
        <div className="col-sm-4 col-xs-4">
          <button
            type="button"
            className="mobile-menu"
            onClick={onToggleMobileMenu}
          />
        </div>
      </div>

      <div className="row info">
        <div className="col-lg-12 col-md-12 col-sm-12 hide-xs">
          <h5>{t("sidebar_title")}</h5>
          <h2>880 045 779</h2>
        </div>
      </div>

      <div className="row navigation">
        <ul
          className={`nav col-lg-12 col-md-12 col-sm-12 col-xs-12 show-md ${
            menuOpened ? "show-xs" : "hide-xs"
          }`}
          role="navigation"
        >
          {props.items.map((item) => (
            <li>
              <a href="" data-href={item.slug} onClick={navigateTo}>
                {item.title}
              </a>
            </li>
          ))}
          <li>
            {language === "pl" && (
              <a
                href=""
                className="language-link"
                onClick={changeLanguage("en")}
              >
                <img src={getThemePath("/images/flag_gb.svg")} alt="English" />
                English
              </a>
            )}
            {language === "en" && (
              <a
                href=""
                className="language-link"
                onClick={changeLanguage("pl")}
              >
                <img src={getThemePath("/images/flag_pl.svg")} alt="Polski" />
                Polski
              </a>
            )}
          </li>
        </ul>
      </div>

      <div className="row banner hide show-md">
        <div className="col-lg-12 col-md-12">
          <a href="http://www.osprzetzeglarski.eu/" target="_blank">
            <img
              src={getThemePath("/images/szekla_sklep.png")}
              alt="Szekla Osprzęt żeglarsk"
            />
          </a>
          <p style="font-size:12px;text-align:center;">{t("sidebar_ad")}</p>
        </div>
      </div>
    </aside>
  );
};
