import { t } from "./language";

export const Header = () => (
  <header role="header" mobile-landing="">
    <div className="floating">
      <h2>{t("main_rent_boat")}</h2>
      <h1>{t("main_szekla_charters")}</h1>
      <a href="tel:880045779" className="phone">
        {t("main_phone")}: 880 045 779
      </a>
    </div>
  </header>
);
