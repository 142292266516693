const API_URL = import.meta.env.VITE_API_URL;

export interface Boat {
  title: string;
  slug: string;
  content: string;
  gallery: {
    thumb: string;
    large: string;
    xlarge: string;
  }[];
  pricing: {
    time: string;
    price: string;
  }[];
  speed: string;
  count: string;
  licence: boolean;
  promo: number;
}

export interface Page {
  slug: string;
  title: string;
  content: string;
}

export interface WPItem {
  slug: string;
  link: string;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
}

export const getPostBySlug = (slug: string): Promise<WPItem> => {
  return fetch(`${API_URL}/wp-json/wp/v2/posts?slug=${slug}`)
    .then((response) => response.json())
    .then(([data]) => data);
};

export const getBoats = (lang: string): Promise<Boat[]> => {
  return fetch(`${API_URL}/wp-json/custom/v1/boats?lang=${lang}`).then(
    (response) => response.json()
  );
};

export const getPageBySlug = (slug: string, lang: string) => {
  return fetch(`${API_URL}/wp-json/wp/v2/pages?slug=${slug}&lang=${lang}`)
    .then((response) => response.json())
    .then(([response]) => response);
};

export const getPages = (lang: string) => {
  return fetch(`${API_URL}/wp-json/custom/v1/pages?lang=${lang}`).then(
    (response) => response.json()
  );
};

export const getLanguages = () => {
  return fetch(`${API_URL}/wp-json/custom/v1/languages`).then((response) =>
    response.json()
  );
};

export const getPostDetails = (url: string) => {
  return fetch(`${API_URL}${url}`).then((response) => response.json());
};
