import { useContext, useEffect, useState } from "preact/hooks";
import Router from "preact-router";
import { Link } from "preact-router/match";
import clsx from "clsx";

import { Sidebar } from "./components/sidebar";
import { Header } from "./components/header";
import { Modal } from "./components/modal";
import { Boat, getBoats, getPages, Page } from "./services/requests";
import { BoatContext } from "./app.context";
import { LanguageContext, t } from "./components/language";
import { getThemePath } from "./utils";

export function App() {
  const [, slug] = location.pathname.split("/").filter(Boolean);
  const [lang] = useContext(LanguageContext);

  const [selectedBoat, setSelectedBoat] = useState<Boat | null>(null);
  const [items, setItems] = useState<Boat[]>([]);
  const [pages, setPages] = useState<Page[]>([]);

  useEffect(() => {
    getPages(lang).then((data) => setPages(data));
    getBoats(lang).then((boats) => {
      setItems(boats);

      if (slug) {
        const boat = boats.find((boat) => boat.slug === slug);

        if (boat) {
          setSelectedBoat(boat);
        }
      }
    });
  }, [lang]);

  const onTileClick = (boat: Boat) => () => {
    setSelectedBoat(boat);
  };

  return (
    <BoatContext.Provider value={selectedBoat}>
      <Router>
        <div path="/" />
        <div path="/:lang" />
        <Modal path="/:lang/:slug" />
      </Router>
      <Sidebar items={pages} />
      <main role="main">
        <Header />
        <section className="container-fluid boats" id="boats">
          <div className="row">
            {items.map((item) => (
              <div
                className={clsx({
                  "col-xlg-3 col-lg-4 col-md-4 col-sm-4 col-xs-12": !item.promo,
                  "col-xlg-6 col-lg-6 col-md-4 col-sm-4 col-xs-12": item.promo,
                })}
              >
                <Link
                  href={`/${lang}/${item.slug}`}
                  className="boat-description"
                  onClick={onTileClick(item)}
                >
                  <img
                    src={item?.gallery?.[0]?.large}
                    alt="Boat"
                    loading="lazy"
                    className="lead-image"
                  />
                  <p
                    className={clsx("title", {
                      "text-red": item.promo,
                    })}
                  >
                    {item.title}
                  </p>
                  {!item.promo && (
                    <div className="row description">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <img
                          src={getThemePath("/images/osoby.svg")}
                          alt="Ilość osób"
                        />
                        <span>
                          {item.count} {t("main_people")}
                        </span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <img
                          src={getThemePath("/images/silnik.svg")}
                          alt="Prędkość"
                        />
                        <span>{item.speed} KM</span>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <p>{t("main_price")}</p>
                        <span>od {item?.pricing?.[0]?.price}</span>
                      </div>
                    </div>
                  )}
                </Link>
              </div>
            ))}
          </div>
        </section>

        {pages
          .filter((item) => Boolean(item.content))
          .map((item) => (
            <section className="container-fluid about" id={item.slug}>
              <h4>{item.title}</h4>
              <div className="container">
                <div
                  className="row"
                  dangerouslySetInnerHTML={{ __html: item?.content ?? "" }}
                />
              </div>
            </section>
          ))}

        <footer className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xlg-6">
              <p className="pull-left">
                {t("footer_legal")}{" "}
                <a href="http://www.osprzetzeglarski.eu/" target="_blank">
                  {t("main_szekla_charters")}
                </a>
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xlg-6">
              <p style="float:right;font-size:14px;">
                {t("credicts")}:{" "}
                <a href="http://webhiro.com/" target="_blank">
                  webhiro.com
                </a>
              </p>
            </div>
          </div>
        </footer>
      </main>
    </BoatContext.Provider>
  );
}
