import { createContext } from "preact";
import { StateUpdater, useCallback, useEffect, useState } from "preact/hooks";
import { route } from "preact-router";

import pl from "../../public/i18n/pl.json";
import en from "../../public/i18n/en.json";

const supportedLang = ["pl", "en"];

const getLang = (paramLang?: string) => {
  const [browserLang] = window.navigator.language.split("-");

  if (paramLang && supportedLang.includes(paramLang)) {
    return paramLang;
  }

  if (supportedLang.includes(browserLang)) {
    return browserLang;
  }

  // fallback
  return "pl";
};

export const LanguageContext = createContext<[string, StateUpdater<string>]>([
  getLang(),
  () => {},
]);

interface Props {
  children: any;
}

export const t = (key: keyof typeof pl) => {
  const [paramsLang] = location.pathname.split("/").filter(Boolean);
  const source = paramsLang === "pl" ? pl : en;

  return source[key];
};

export const Language = ({ children }: Props) => {
  const [paramsLang] = location.pathname.split("/").filter(Boolean);
  const [lang, setLang] = useState<string>(getLang(paramsLang));

  useEffect(() => {
    if (lang !== paramsLang) {
      route(`/${lang}`);
    }
  }, [lang]);

  return (
    <LanguageContext.Provider value={[lang, setLang]}>
      {children}
    </LanguageContext.Provider>
  );
};
