import { render } from "preact";
import { App } from "./app";

import "./assets/styles.css";
import { Language } from "./components/language";

render(
  <Language>
    <App />
  </Language>,
  document.getElementById("app")!
);
