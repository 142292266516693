import { Link } from "preact-router/match";
import { useContext, useEffect, useState } from "preact/hooks";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { BoatContext } from "../app.context";
import { LanguageContext, t } from "./language";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Boat } from "../services/requests";
import { getThemePath } from "../utils";
import clsx from "clsx";

const DEFAULT_BOAT: Boat = {
  count: "10",
  licence: false,
  promo: 0,
  slug: "default-boat",
  speed: "200",
  title: "Ladowanie lodzi",
  content: "Ladowanie lodzi",
  pricing: [
    {
      time: "1 godzina z paliwem",
      price: "-",
    },
  ],
  gallery: [],
};

export const Modal = (props: { path: string }) => {
  const [lang] = useContext(LanguageContext);
  const boat = useContext(BoatContext) || DEFAULT_BOAT;
  const [selectedTime, setSelectedTime] = useState<string | undefined>();

  useEffect(() => {
    document.body.classList.add("lock");

    return () => {
      document.body.classList.remove("lock");
    };
  });

  useEffect(() => {
    setSelectedTime(boat?.pricing?.[0].price);
  }, [boat]);

  const bigSliderOptions = {
    type: "loop",
    perPage: 1,
    perMove: 1,
    height: 600,
    breakpoints: {
      992: {
        height: 320,
      },
    },
    cover: true,
    gap: "0",
    pagination: false,
  };

  const onTimeSelect = (event: any) => {
    setSelectedTime(event.target.value);
  };

  return (
    <div className="modal">
      <div className="modal-container flex flex-col md:flex-row md:block">
        <header className="mobile-header">
          <a href="">
            <img
              src={getThemePath("/images/logo_mobile.png")}
              alt="Szekla"
              className="mobile"
            />
          </a>
        </header>
        <Link href={`/${lang}`} className="close">
          ×
        </Link>

        <div className="row h-full flex flex-col md:flex-row">
          <div className="col-xlg-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 p-0">
            <Splide options={bigSliderOptions}>
              {boat?.gallery.map((item) => (
                <SplideSlide>
                  <img src={item.large} className="image-fit" loading="lazy" />
                </SplideSlide>
              ))}
            </Splide>
          </div>

          {/* right side */}
          <div className="col-xlg-5 col-lg-5 col-md-5 col-sm-12 col-xs-12 flex flex-col md:h-full flex-1">
            <h4 className={clsx({ "text-red": boat.promo })}>{boat?.title}</h4>
            <div
              className="content flex-1"
              dangerouslySetInnerHTML={{
                __html: boat?.content ?? "",
              }}
            />

            {!boat?.promo && (
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-6 col-xs-6">
                  <select className="rental" onChange={onTimeSelect}>
                    {Object.values(boat?.pricing ?? {}).map((data) => (
                      <option value={data.price}>{data.time}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6">
                  <div className="price">
                    <p>{t("main_price")}:</p>
                    <small>{selectedTime} PLN</small>
                  </div>
                </div>
              </div>
            )}

            <div className="row desc">
              {!boat?.promo && (
                <>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 details separator br-1">
                    <div className="flex flex-col justify-center items-center info-box">
                      <img
                        src={getThemePath("/images/osoby.svg")}
                        alt="Ilość osób"
                        width={40}
                      />
                      <span>
                        {boat?.count} {t("main_people")}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 details separator br-1">
                    <div className="flex flex-col justify-center items-center info-box">
                      <img
                        src={getThemePath("/images/silnik.svg")}
                        alt="Ilość osób"
                        width={40}
                      />
                      <span>{boat?.speed} KM</span>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 details separator">
                    <div className="flex flex-col justify-center items-center info-box">
                      <img
                        src={getThemePath("/images/uprawnienia.svg")}
                        alt="Ilość osób"
                        width={40}
                      />
                      <span>
                        {Boolean(boat?.licence)
                          ? t("no_licence")
                          : t("licence")}
                      </span>
                    </div>
                  </div>
                </>
              )}
              <div className="col-sm-12 col-xs-12 hide-lg hide-md">
                <a href="tel:880045779" className="btn-phone">
                  Zadzwoń!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
